import 'assets/styles/styles.scss';
import Layout from 'components/AppLayout';
import { createContext } from 'react';
import RootStore from 'store';
import Script from 'next/script';
import Head from 'next/head';

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const rootStore = new RootStore();

  return (
    <StoreContext.Provider
      value={{
        rootStore: rootStore,
        userStore: rootStore.UserStore,
        restaurantStore: rootStore.RestaurantStore,
        cartStore: rootStore.CartStore,
        marketManagementStore: rootStore.MarketManagementStore,
        groupOrderStore: rootStore.GroupOrderStore,
      }}>
      {children}
    </StoreContext.Provider>
  );
};

export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      </Head>

      <Script strategy="lazyOnload">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}')`}
      </Script>

      <Script>
        {`
          (function(ss,ex){
            window.ldfdr=window.ldfdr||function(){
              (ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));
            };
            (function(d,s){
              fs=d.getElementsByTagName(s)[0];
              function ce(src){
                var cs=d.createElement(s);
                cs.src=src;
                cs.async=1;
                fs.parentNode.insertBefore(cs,fs);
              };
              ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js');
            })(document,'script');
          })('${process.env.NEXT_PUBLIC_LEAD_FEEDER_TRACKING_ID}');`}
      </Script>

      <StoreProvider>
        <Layout
          logoLayout={Component?.logoLayout}
          groupOrderLayout={Component?.groupOrderLayout}
          checkoutLayout={Component?.checkoutLayout}
          simpleLayout={Component?.simpleLayout}
          loggedInLayout={Component?.loggedInLayout}
          groupOrderUserLayout={Component?.groupOrderUserLayout}
          groupOrderCheckoutLayout={Component?.groupOrderCheckoutLayout}>
          <Component {...pageProps} />
        </Layout>
      </StoreProvider>
    </>
  );
}
